<!--模板-->
<template>
  <div class="">
    <a-table :columns="columns" :data-source="data" :pagination="false" :bordered="true" :loading="loading">
      <span slot="projectTotal" slot-scope="text, record"> {{ record.hasProjectCount }} / {{ record.totalProjectCount }} </span>
      <span slot="checkStandarNum" slot-scope="text, record"> {{ record.hasRequirementCount }} / {{ record.totalRequirementCount }} </span>
      <span slot="checkContentNum" slot-scope="text, record"> {{ record.hasContentCount }} / {{ record.totalContentCount }} </span>
    </a-table>
  </div>
</template>

<script>
// import component from '@/component/*.vue'
export default {
  data() {
    return {}
  },
  props: {
    columns: {
      type: Array,
      default: [],
    },
    data: {
      type: Array,
      default: [],
    },
    showModals: {
      type: Function,
      default: null,
    },
    loading: {
      type: Boolean,
      default: true
    }
  },
  components: {},
  computed: {},
  filters: {},
  methods: {
    showModal() {
      // this.$parent.showModal()
      this.showModals()
      // this.$emit('showModal')
    },
  },
  created() {},
  mounted() {},
}
</script>

<style lang="less" scoped>
// @import url(); 引入公共css
.right-top {
  margin-bottom: 53px;
  box-sizing: border-box;
  padding: 42px 30px 0 46px;
}
.search {
  display: flex;
  align-content: center;
}
.newBuild {
  display: flex;
  justify-content: flex-end;
}
</style>
